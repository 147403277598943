/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Injectable } from '@angular/core';
import { MONA_CONFIG_DEFAULT } from '@environment';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, pluck, switchMap, take } from 'rxjs/operators';
import { Logger } from '@mona/shared/logger';
import { isNonNullable } from '@mona/shared/utils';
import { Config, ConfigKeys, ConfigLoader, ConfigType, MIGRATIONS, SCHEMA } from '../models';
import { ConfigActions, ConfigSelectors, ConfigState } from '../state';

/**
 * ConfigService
 *
 */
@Injectable()
export class ConfigService {
    /** If config was loaded, replay */
    readonly configLoaded$ = new ReplaySubject<boolean>();
    /** Config object to provide static access via `value` */
    protected _config: Config<ConfigType>;
    /** Get static config object */
    get config(): ConfigType {
        return this._config?.store;
    }
    /** Config state as Obcervable */
    readonly config$ = this.configLoaded$.pipe(switchMap(() => this.store.select(ConfigSelectors.selectConfig)));

    private logger: Logger = new Logger('CONFIG');

    /**
     * Constructor
     *
     * @param loader
     * @param store
     */
    constructor(private readonly loader: ConfigLoader, private readonly store: Store<{ config: ConfigState }>) {}

    /**
     * Init loading & creating {@link Config}
     * On fail create default
     *
     * This function is called only in `APP_INITIALIZER` phase
     */
    async init(): Promise<void> {
        let defaults = MONA_CONFIG_DEFAULT;
        // 1) load
        try {
            const data = await this.loader.load();
            defaults = data;
        } catch (error) {
            this.logger.error(`Failed to load config from server: ${error}`);
        }
        // 2) init
        try {
            this._config = new Config<ConfigType>({
                schema: SCHEMA,
                migrations: MIGRATIONS,
                defaults,
            });
        } catch (error) {
            this.logger.error(`Failed to create config instance:`, error);
            this.store.dispatch(ConfigActions.initConfigFailure({ error }));
            return;
        }
        this.store.dispatch(ConfigActions.initConfig({ config: this._config?.store || defaults }));
        this.configLoaded$.next(true);
        this.configLoaded$.complete();
    }

    /**
     * Get nested config
     *
     * @param path string separated with dots or array of keys
     */
    get<K extends ConfigKeys>(path: K): PathValue<ConfigType, K> {
        return this._config.get(path);
    }

    /**
     * Get nested config as observable
     *
     * @param path string separated with dots or array of keys
     */
    select<K extends ConfigKeys>(path?: K): Observable<PathValue<ConfigType, K>> {
        const keys = path.split('.');
        return this.config$.pipe(pluck(...keys));
    }

    /**
     * Update config state with partial data, only of `ConfigType`
     *
     * @param partialConfig ConfigType
     */
    update(partialConfig: DeepPartial<ConfigType>): void {
        this.store.dispatch(ConfigActions.updateConfig({ config: partialConfig }));
    }

    /**
     * Update config from remote using async loader
     *
     * @param loader ConfigLoader
     */
    async updateFromRemote(loader: ConfigLoader): Promise<void> {
        const partialConfig: DeepPartial<ConfigType> = await loader.load().catch(error => ({}));
        this.store.dispatch(ConfigActions.updateConfig({ config: partialConfig }));
    }

    /**
     * Emit config event for external use like lforms
     *
     * @param config ConfigType
     */
    dispatchConfigEvent(config: DeepPartial<ConfigType>) {
        const configUpdateEvent = new CustomEvent('mona:config:update', {
            detail: config,
        });
        document.dispatchEvent(configUpdateEvent);
    }
}
