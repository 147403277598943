import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { ChangeLogEntry, TherapyLimitations } from '@mona/models';
import {
    applyInstancesChanges,
    ChangeLogAction,
    ChangeLogSelectors,
    getPersistedChangesData,
} from '@mona/pdms/data-access-changelog';
import { applyLastChangedByToPrescriptions, getCurrentEncounterProp } from '@mona/pdms/data-access-combined';
import { PractitionersFacade } from '@mona/pdms/data-access-practitioners';
import { TherapyLimitationsApi } from '../../infrastructure';
import { TherapyLimitationsActions } from '../actions';
import { selectTherapyLimitationsAll } from '../selectors';

/**
 * TherapyLimitations effects
 */
@Injectable()
export class TherapyLimitationsEffects {
    changeLogMap$ = this.store.select(ChangeLogSelectors.getChangesMap);
    therapyLimitationsAll$ = this.store.select(selectTherapyLimitationsAll);

    /** Load Therapy Limitations */
    loadTherapyLimitations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TherapyLimitationsActions.loadTherapyLimitations),
            withLatestFrom(getCurrentEncounterProp('patientId')),
            concatMap(([, patientId]) =>
                this.therapyLimitationsApi.getTherapyLimitations(patientId).pipe(
                    withLatestFrom(this.changeLogMap$, this.practitionersFacade.practitionersMap$),
                    map(([data, changesMap, practitionersMap]) => {
                        const changes = changesMap['TherapyLimitations'] || [];

                        if (changes.length) {
                            data = applyInstancesChanges(data, changes) as TherapyLimitations[];
                        }

                        data = applyLastChangedByToPrescriptions(data, practitionersMap);

                        return TherapyLimitationsActions.loadTherapyLimitationsSuccess({ data });
                    }),
                    catchError(error => of(TherapyLimitationsActions.loadTherapyLimitationsFailure({ error }))),
                ),
            ),
        ),
    );

    /** Listen change save success & upsert entity + show message */
    onChangeSaved$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ChangeLogAction.saveChangeAction.succeededAction,
                    ChangeLogAction.saveChangesAction.succeededAction,
                ),
                withLatestFrom(this.therapyLimitationsAll$, this.changeLogMap$),
                tap(([, data, changesMap]) => {
                    const changes: ChangeLogEntry<TherapyLimitations>[] = changesMap['TherapyLimitations'] || [];

                    if (changes.length) {
                        const therapyLimitations = applyInstancesChanges(data, changes);

                        this.store.dispatch(
                            TherapyLimitationsActions.upsertTherapyLimitations({ data: therapyLimitations }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /** Listen change persist success & add one - realistic update */
    onChangePersisted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(
                    this.therapyLimitationsAll$,
                    this.changeLogMap$,
                    this.practitionersFacade.practitionersMap$,
                ),
                tap(([, data, changesMap, practitionersMap]) => {
                    const changes = changesMap['TherapyLimitations'] || [];

                    if (changes.length) {
                        const removeUpdateData = getPersistedChangesData(data, changes);
                        removeUpdateData.toUpdate = applyLastChangedByToPrescriptions(
                            removeUpdateData.toUpdate,
                            practitionersMap,
                        );

                        this.store.dispatch(
                            TherapyLimitationsActions.addTherapyLimitationsChanges({
                                toUpdateEntities: removeUpdateData.toUpdate || [],
                                toRemoveIds: removeUpdateData.toRemove.map(({ id }) => id) || [],
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param store
     * @param actions$
     * @param therapyLimitationsApi
     * @param practitionersFacade
     */
    constructor(
        private store: Store<any>,
        private actions$: Actions,
        private therapyLimitationsApi: TherapyLimitationsApi,
        private practitionersFacade: PractitionersFacade,
    ) {}
}
