import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { auditLogReducer } from '../../../data-access-encounter-data/src/lib/state/reducers/audit-log.reducer';
import { encountersFeatureKey } from './entities';
import { EncountersEffects, ENCOUNTERS_REDUCER_TOKEN, encountersReducer } from './state';

/**
 * DataAccessEncounters Module
 */
@NgModule({
    imports: [
        StoreModule.forFeature(encountersFeatureKey, ENCOUNTERS_REDUCER_TOKEN),
        EffectsModule.forFeature([EncountersEffects]),
        StoreModule.forFeature('auditLog', auditLogReducer),
    ],
    providers: [
        {
            provide: ENCOUNTERS_REDUCER_TOKEN,
            useFactory: () => encountersReducer,
        },
    ],
})
export class DataAccessEncountersModule {}
