import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { ChangeLogEntry, VaccinationStatus } from '@mona/models';
import {
    applyInstancesChanges,
    ChangeLogAction,
    ChangeLogSelectors,
    getPersistedChangesData,
} from '@mona/pdms/data-access-changelog';
import { applyLastChangedByToPrescriptions, getCurrentEncounterProp } from '@mona/pdms/data-access-combined';
import { PractitionersFacade } from '@mona/pdms/data-access-practitioners';
import { VaccinationStatusApi } from '../../infrastructure';
import { VaccinationsStatusAction } from '../actions';
import { selectVaccinationAll } from '../selectors';

/**
 * Vaccinations effects
 */
@Injectable()
export class VaccinationsEffects {
    changeLogMap$ = this.store.select(ChangeLogSelectors.getChangesMap);
    selectVaccinationAll$ = this.store.select(selectVaccinationAll);

    /** Load Vaccinations */
    loadVaccinations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VaccinationsStatusAction.loadVaccinations),
            withLatestFrom(getCurrentEncounterProp('patientId')),
            concatMap(([, patientId]) =>
                this.vaccinationStatusApi.getVaccinationStatus(patientId).pipe(
                    withLatestFrom(this.changeLogMap$, this.practitionersFacade.practitionersMap$),
                    map(([data, changesMap, practitionersMap]) => {
                        const changes = changesMap['VaccinationStatus'] || [];

                        if (changes.length) {
                            data = applyInstancesChanges(data, changes) as VaccinationStatus[];
                        }

                        data = applyLastChangedByToPrescriptions(data, practitionersMap);

                        return VaccinationsStatusAction.loadVaccinationsSuccess({ data });
                    }),
                    catchError(error => of(VaccinationsStatusAction.loadVaccinationsFailure({ error }))),
                ),
            ),
        ),
    );

    /** Listen change save success & upsert entity + show message */
    onChangeSaved$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ChangeLogAction.saveChangeAction.succeededAction,
                    ChangeLogAction.saveChangesAction.succeededAction,
                ),
                withLatestFrom(this.selectVaccinationAll$, this.changeLogMap$),
                tap(([, data, changesMap]) => {
                    const changes: ChangeLogEntry<VaccinationStatus>[] = changesMap['VaccinationStatus'] || [];

                    if (changes.length) {
                        const vaccinations = applyInstancesChanges(data, changes);

                        this.store.dispatch(VaccinationsStatusAction.upsertVaccinations({ data: vaccinations }));
                    }
                }),
            ),
        { dispatch: false },
    );

    /** Listen change persist success & add one - realistic update */
    onChangePersisted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(
                    this.selectVaccinationAll$,
                    this.changeLogMap$,
                    this.practitionersFacade.practitionersMap$,
                ),
                tap(([, data, changesMap, practitionersMap]) => {
                    const changes = changesMap['VaccinationStatus'] || [];

                    if (changes.length) {
                        const removeUpdateData = getPersistedChangesData(data, changes);
                        removeUpdateData.toUpdate = applyLastChangedByToPrescriptions(
                            removeUpdateData.toUpdate,
                            practitionersMap,
                        );

                        this.store.dispatch(
                            VaccinationsStatusAction.addVaccinationsChanges({
                                toUpdateEntities: removeUpdateData.toUpdate || [],
                                toRemoveIds: removeUpdateData.toRemove.map(({ id }) => id) || [],
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param store
     * @param actions$
     * @param vaccinationStatusApi
     * @param practitionersFacade
     */
    constructor(
        private store: Store<any>,
        private actions$: Actions,
        private vaccinationStatusApi: VaccinationStatusApi,
        private practitionersFacade: PractitionersFacade,
    ) {}
}
