/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { ChangeLogEntry, Procedure } from '@mona/models';
import {
    applyInstancesChanges,
    ChangeLogAction,
    ChangeLogSelectors,
    getPersistedChangesData,
} from '@mona/pdms/data-access-changelog';
import { withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { ProceduresApi } from '../../infrastructure';
import { ProceduresActions } from '../actions';
import { addProceduresChanges } from '../actions/procedure.actions';
import { selectProcedureAll } from '../selectors';

/**
 * ProceduresEffects
 */
@Injectable()
export class ProceduresEffects {
    changeLogMap$ = this.store.select(ChangeLogSelectors.getChangesMap);
    procedurePrescriptionAll$ = this.store.select(selectProcedureAll);

    /** Load Procedures */
    loadProcedures$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProceduresActions.loadProcedures),
            withCurrentEncounterId(),
            concatMap(([, encounterId]) =>
                this.proceduresApi.getProcedures(encounterId).pipe(
                    withLatestFrom(this.changeLogMap$),
                    map(([data, changesMap]) => {
                        const changes = changesMap['Procedure'] || [];

                        if (changes.length) {
                            data = applyInstancesChanges(data, changes) as Procedure[];
                        }

                        return ProceduresActions.loadProceduresSuccess({
                            procedures: data,
                        });
                    }),
                    catchError(error => of(ProceduresActions.loadProceduresFailure({ error }))),
                ),
            ),
        ),
    );

    /** Listen change save success & upsert entity + show message */
    onChangeSaved$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ChangeLogAction.saveChangeAction.succeededAction,
                    ChangeLogAction.saveChangesAction.succeededAction,
                ),
                withLatestFrom(this.procedurePrescriptionAll$, this.changeLogMap$),
                tap(([, data, changesMap]) => {
                    const changes: ChangeLogEntry<Procedure>[] = changesMap['Procedure'] || [];

                    if (changes.length) {
                        let procedures = applyInstancesChanges(data, changes);
                        // To add temporary lastChangedAt for procedure changes
                        procedures = procedures.map(procedure => {
                            if (procedure.id === changes[changes.length - 1].modelId) {
                                return { ...procedure, lastChangedAt: new Date() };
                            }
                            return procedure;
                        });

                        this.store.dispatch(
                            ProceduresActions.upsertProcedures({
                                procedures,
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /** Listen change persist success & add one - realistic update */
    onChangePersisted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(this.procedurePrescriptionAll$, this.changeLogMap$),
                tap(([, data, changesMap]) => {
                    const changes = changesMap['Procedure'] || [];

                    if (changes.length) {
                        const { toRemove, toUpdate } = getPersistedChangesData(data, changes);

                        this.store.dispatch(
                            addProceduresChanges({
                                toUpdateEntities: toUpdate || [],
                                toRemoveIds: toRemove.map(({ id }) => id) || [],
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param store Store
     * @param actions$ Actions
     * @param proceduresApi ProceduresApi
     */
    constructor(private store: Store<any>, private actions$: Actions, private proceduresApi: ProceduresApi) {}
}
