import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Inject, Injectable, isDevMode, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DEFAULT_NAVBAR_ITEMS, environment, getRedirectPath, GIT_VERSION, ROUTES_MAP_BY_FEATURE } from '@environment';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, NEVER, Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ApiHealthFacade } from '@mona/api';
import { AuthService, ConfirmLockScreenService, User } from '@mona/auth';
import { ConfigService } from '@mona/config';
import { UPDATER, UpdatesService } from '@mona/core';
import { DeviceFacade } from '@mona/device/data-access-device';
import { UpdateDialogComponent } from '@mona/device/shared';
import { FeatureFlagsService, FEATURE_FLAGS } from '@mona/flags';
import { AutoLock, CallStatus, TerminalConfig } from '@mona/models';
import { isAbleToEdit } from '@mona/pdms/data-access-combined';
import { DataAccessExternalResourcesFacade } from '@mona/pdms/data-access-external-resources';
import { DataAccessTaskListFacade } from '@mona/pdms/data-access-task-list';
import { Logger, WithLogger } from '@mona/shared/logger';
import {
    clone,
    IDLE,
    IdleEventEnum,
    IdleService,
    isEmpty,
    isNullOrUndefined,
    isUndefined,
    NAVIGATION,
    NavigationService,
    notEmpty,
    omit,
    outletRouteMatcher,
    PLATFORM,
    Platform,
    stringify,
    SYNC_STORAGE,
    takeUntilDestroy,
    TakeUntilDestroy,
} from '@mona/shared/utils';
import { AppState, AppStateFacade } from '@mona/store';
import { CallService, CallWebrtcService } from '@mona/telemedicine/data-access-mona';
import { ActiveCallDialog } from '@mona/telemedicine/shared';
import { DialogService, DrawerNavItem, DrawerService, LayoutConfig, LayoutEventType } from '@mona/ui';

/**
 * App service (navigation etc)
 */
@Injectable({
    providedIn: 'root',
})
@TakeUntilDestroy
@WithLogger({
    methodOptions: {
        withArgs: true,
    },
    loggedMethodsNames: ['!generateLayoutConfig'],
})
export class AppService implements OnDestroy, WithLogger {
    autoLock$ =
        isDevMode() && environment.disableLockScreenAtStartup
            ? of(AutoLock.NEVER)
            : this.configService.select('autoLock');

    /** Default Navbar Items */
    readonly navbarItems: LayoutConfig['navbarItems'] = clone(DEFAULT_NAVBAR_ITEMS);

    /**
     * Whether the core is in maintenance or not
     */
    isCoreInMaintenance$: Observable<boolean> = this.apiHealthFacade.isInMaintenance$(
        this.configService.get('api.baseUrl'),
    );

    /**
     * Whether is terminal version incompatible with core
     */
    isVersionIncompatibleToCore$: Observable<boolean> = this.apiHealthFacade.isVersionIncompatible$(
        this.configService.get('api.baseUrl'),
    );

    /** Get device activated value */
    isDeviceActivated$: Observable<boolean> = this.deviceFacade.isActivated$.pipe(notEmpty());

    /**
     * Status of the call feature
     */
    callStatus$: Observable<CallStatus> = this.callService.getCallStatus();

    /** Authenticated user object */
    user$: Observable<User> = this.authService.user$;

    /** App Alert messages */
    alerts$ = this.appStateFacade.messages$;

    /**
     * Is encounter in review mode
     */
    isAbleToEdit$: Observable<boolean> = isAbleToEdit();

    /** App Theme */
    theme$ = this.appStateFacade.theme$;
    /** Left navbar navigation config */
    readonly layoutConfig$ = this.layoutService?.layoutConfig$;
    readonly logger: Logger; // correct
    private timeToCloseWebBrowserAfter = 900000;
    private updateAvailable: any;
    private updateReady: any;

    /**
     * Constructor
     *
     * @param platform
     * @param featureFlags
     * @param updatesService
     * @param navigationService
     * @param storageService
     * @param idleService
     * @param layoutService
     * @param configService
     * @param store
     * @param router
     * @param activatedRoute
     * @param authService
     * @param appStateFacade
     * @param apiHealthFacade
     * @param deviceFacade
     * @param dialogService
     * @param callService
     * @param callWebrtcService
     * @param confirmLockScreenService
     * @param externalResourcesFacade
     * @param taskListFacade
     */
    constructor(
        @Inject(PLATFORM) public platform: Platform,
        @Inject(FEATURE_FLAGS) public featureFlags: FeatureFlagsService,
        @Inject(UPDATER) private updatesService: UpdatesService,
        @Inject(NAVIGATION) private navigationService: NavigationService,
        @Inject(SYNC_STORAGE) private storageService: Storage,
        @Inject(IDLE) private idleService: IdleService,
        private layoutService: DrawerService,
        private configService: ConfigService,
        private store: Store<AppState>,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private appStateFacade: AppStateFacade,
        private apiHealthFacade: ApiHealthFacade,
        private deviceFacade: DeviceFacade,
        private dialogService: DialogService,
        private callService: CallService,
        private callWebrtcService: CallWebrtcService,
        private confirmLockScreenService: ConfirmLockScreenService,
        private externalResourcesFacade: DataAccessExternalResourcesFacade,
        private taskListFacade: DataAccessTaskListFacade,
    ) {
        this.logger.log('PLATFORM', stringify(omit(this.platform, '_win', 'location')));
        this.logger.log('ENVIRONMENT', stringify(environment));
        this.logger.log('GIT', stringify(GIT_VERSION));
    }

    /** Toggles some features for platform */
    checkPlatformFeatures() {
        if (this.featureFlags.is('mona')) {
            return;
        }
        const updatedConfig = {};

        if (!this.featureFlags.has('pdms')) {
            Object.assign(updatedConfig, { features: { pdms: true } });
        }
        if (this.featureFlags.has('telemedicine')) {
            Object.assign(updatedConfig, { features: { telemedicine: false } });
        }
        if (this.configService.get('isBedSide')) {
            Object.assign(updatedConfig, { isBedSide: false });
        }
        if (!isEmpty(updatedConfig)) {
            this.configService.update(updatedConfig);
        }
    }

    /**
     * Start listening to RFID scanner if Electron
     */
    initRFIDListener() {
        if (this.platform.isElectron) {
            this.authService.listenToRfidScanner();
        }
    }

    /**
     * Init navigation listener & Collect route events
     */
    initNavigationListener(): void {
        this.router.events
            .pipe(
                filter<NavigationEnd>(
                    event => event instanceof NavigationEnd && !outletRouteMatcher(event.urlAfterRedirects, 'side'),
                ),
                withLatestFrom(this.configService.config$),
            )
            .subscribe(([event, config]) => {
                let route = this.activatedRoute.snapshot;

                while (route.firstChild) {
                    route = route.firstChild;

                    if (route.data?.layoutConfig) {
                        this.generateLayoutConfig(route.data, config, event);
                    }
                }
            });
    }

    /** Performs initial navigation and redirect after login */
    initRedirect() {
        const urlPath = this.navigationService.currentLocationPath;
        if (!urlPath) {
            this.navigateToSetupPage();
        } else if (urlPath.startsWith('/auth')) {
            this.router.navigateByUrl('auth/lock-screen?full=true'); // FIXME: improve initial auth navigation
        } else {
            this.navigationService.navigateByUrlWithEmptyOutlets(urlPath);
        }

        this.authService.isLoggedIn$
            .pipe(
                filter(Boolean),
                tap(() => {
                    const assignedEncounterId = this.storageService.getItem('pdms.assignedEncounterId');
                    const { urlBeforeLogout } = this.navigationService.currentLocationState;
                    const urlPath = this.navigationService.currentLocationPath;
                    // if web location state has prev url OR device has assigned bed id - navigate with history
                    const shouldNavigatePrevUrl =
                        !!urlBeforeLogout &&
                        !urlBeforeLogout?.includes('auth') &&
                        (this.platform.isElectron ? !isNullOrUndefined(assignedEncounterId) : true);

                    if (shouldNavigatePrevUrl) {
                        this.navigationService.goBack();
                        // else check if this is first enter/exit navigation
                    } else if (urlPath.length <= 1 || urlPath.startsWith('/auth')) {
                        this.navigateToLicencesStartPage();
                        // redirect to page based on license config, if current url doesn't match default path, also close all outlets
                    } else if (!this.platform.isElectron) {
                        const hasHistoryOpened = this.navigationService.hasOpenOutlet('side', 'history');
                        this.navigationService.navigateByUrlWithEmptyOutlets(
                            urlPath,
                            hasHistoryOpened ? ['pdms', 'history'] : [],
                        );
                    }
                }),
                takeUntilDestroy(this),
            )
            .subscribe();
    }

    /**
     * INFO: add comment
     */
    initLogoutListener() {
        this.authService.isAuthenticated$
            .pipe(
                filter(Boolean),
                switchMap(() => this.isVersionIncompatibleToCore$.pipe(take(1))),
                takeUntilDestroy(this),
            )
            .subscribe(isVersionIncompatible => {
                if (isVersionIncompatible) {
                    this.handleLogOut(true);
                }
            });

        this.isCoreInMaintenance$
            .pipe(
                filter(isCoreInMaintenance => !!isCoreInMaintenance),
                takeUntilDestroy(this),
            )
            .subscribe(() => {
                // only trigger it if it is in maintenance, if not we should not dispatch an action again
                this.handleLogOut(true);
            });
    }

    /**
     * Init auto lock
     */
    initAutoLock() {
        this.idleService.on(IdleEventEnum.TimeoutWarning, countdown => {
            this.logger.log(`IdleEvent: User has ${countdown} seconds to come back!`);
        });

        this.idleService.on(IdleEventEnum.UserHasTimedOut, () => {
            this.handleLogOut(true);
        });

        /**
         * If status is present - then call is opened (otherwise status is undefined) OR if previous call status was present but now it is absent
         */
        if (this.featureFlags.check('telemedicine:mona')) {
            this.callService
                .getCallStatus()
                .pipe(distinctUntilChanged(), pairwise(), takeUntilDestroy(this))
                .subscribe(([prevCallStatus, callStatus]) => {
                    // If status is present - then call is opened (otherwise status is undefined)
                    if (callStatus) {
                        this.idleService.pause();
                    } else if (prevCallStatus) {
                        // If previous call status was present but now it is absent
                        this.idleService.resume();
                    }
                });
        }

        /**
         * Start/Stop auto-lock if configuration changed
         */
        this.autoLock$.pipe(distinctUntilChanged(), takeUntilDestroy(this)).subscribe(msToIdle => {
            this.idleService.configure({
                listenFor: 'mousemove keydown mousedown',
                msToIdle,
                enabled: msToIdle !== AutoLock.NEVER,
            });
            if (msToIdle == AutoLock.NEVER) {
                this.idleService.stop();
            } else if (this.idleService.state.isRunning) {
                this.idleService.reset();
            }
        });

        // Stop auto-lock  if app is on Mona device and device is not setted up
        if (this.featureFlags.is('mona')) {
            this.isDeviceActivated$.pipe(take(1)).subscribe(isDeviceActivated => {
                if (!isDeviceActivated) {
                    this.idleService.stop();
                }
            });
        }

        // Stop auto-lock when user is logged out
        this.authService.isLoggedOut$.pipe(takeUntilDestroy(this)).subscribe(() => {
            this.idleService.stop();
        });

        // Start auto-lock when user is logged in
        this.authService.isAuthenticated$.pipe(filter(Boolean), takeUntilDestroy(this)).subscribe(() => {
            this.idleService.start();
        });

        this.subscribeOnCloseExternalResources();
    }

    /**
     * Listen for custom event dispatched from layout
     */
    handleLayoutEvents() {
        this.layoutService.on(LayoutEventType.LogOut, () => {
            this.handleLogOut();
        });
        this.layoutService.on(LayoutEventType.DblCkickLogo, () => {
            this.appStateFacade.toggleTheme();
        });
    }

    /**
     * Close browser with external resources after they haven't been used for some amount of time
     */
    subscribeOnCloseExternalResources(): void {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                map((event: NavigationStart) => event.url),
                pairwise(),
                filter(
                    ([previosUrl, currentUrl]) =>
                        previosUrl.includes('external-resources') || currentUrl.includes('external-resources'),
                ),
                withLatestFrom(this.externalResourcesFacade.selectedExternalResource$),
                switchMap(([[previosUrl, currentUrl], selectedResource]) => {
                    const isMovingAwayFromExternalResources =
                        previosUrl.includes('external-resources') && !currentUrl.includes('external-resources');
                    return isMovingAwayFromExternalResources && selectedResource
                        ? timer(this.timeToCloseWebBrowserAfter).pipe(map(() => selectedResource))
                        : EMPTY;
                }),
                takeUntilDestroy(this),
            )
            .subscribe(() => {
                this.externalResourcesFacade.browserEndSession();
                this.externalResourcesFacade.clearSelectedExternalResource();
            });
    }

    /** Check if overdueTime has come from BE and write it to the store */
    checkTasksOverdueTime(): void {
        const overdueTime = this.configService.get('overdueTime');
        this.taskListFacade.setOverdueTime(overdueTime);
    }

    /**
     * Click handler for logout button.
     * Holds logout process in case of an active video call.
     *
     * @param isAutoLogout boolean
     */
    handleLogOut(isAutoLogout = false): void {
        // Run lock guard
        // TODO: check if this flow is still valid with Web
        (isAutoLogout ? of(true) : this.confirmLockScreenService.confirmLockScreen()).subscribe(async lockConfirmed => {
            // If lock guard returned true
            if (lockConfirmed) {
                // INFO: skip for web (kind of)
                if (this.featureFlags.is('mona')) {
                    // Get current call status
                    const callStatus = await this.callStatus$.pipe(take(1)).toPromise();

                    // Wait with logout until we know what to do with the active call
                    // Anyway if call is opened
                    if (callStatus) {
                        const shouldHold = await this.dialogService.open(ActiveCallDialog).toPromise();
                        if (shouldHold) {
                            this.callWebrtcService.sendOnHold();
                        } else {
                            this.callWebrtcService.sendHangUp();
                        }
                        this.callWebrtcService.stopCall();
                        this.callService.clearCreateCallSession();
                        return this.router.navigate(['/telemedicine']);
                    }
                }

                // Show lock screen
                this.cleanUpWebBrowser();
                this.authService.logOut();
                this.idleService.stop();
            }
        });
    }

    /**
     * Clean opened web browser
     */
    cleanUpWebBrowser(): void {
        this.externalResourcesFacade.browserEndSession();
        this.externalResourcesFacade.clearSelectedExternalResource();
    }

    /**
     * Handles update available, shows update dialog if needed
     */
    handleUpdateAvailable() {
        this.updatesService.init();
        this.updatesService.updateReady$
            .pipe(takeUntilDestroy(this))
            .subscribe(updateReady => (this.updateReady = updateReady));

        combineLatest([this.callService.getCallStatus(), this.isVersionIncompatibleToCore$])
            .pipe(
                filter(
                    ([callStatus, isVersionIncompatible]) =>
                        isVersionIncompatible && !callStatus && !this.dialogService.getDialogById('update-dialog'),
                ),
                takeUntilDestroy(this),
            )
            .subscribe(() => {
                this.dialogService
                    .open(
                        UpdateDialogComponent,
                        {
                            title: 'apps.settings.updateDialog.title',
                            description: 'apps.settings.updateDialog.description',
                            confirmBtn: 'apps.settings.updateDialog.updateBtn',
                            cancelBtn: '',
                        },
                        {
                            id: 'update-dialog',
                            closeOnNavigation: false,
                        },
                    )
                    .subscribe((confirmed: boolean) => {
                        if (confirmed) {
                            if (this.featureFlags.is('mona')) {
                                // trigger update system
                                this.deviceFacade.sendSystemReboot();
                            }
                        }
                    });
            });
    }

    /**
     * Navigates to setup or start page or setup
     */
    navigateToSetupPage(): void {
        this.navigationService.navigateByUrlWithEmptyOutlets(ROUTES_MAP_BY_FEATURE.DEVICE_SETUP);
    }

    /**
     * Navigate to start page based on licenses when setup has run, device is registered
     */
    navigateToLicencesStartPage(): void {
        const path = getRedirectPath(this.configService.config);
        this.navigationService.navigateByUrlWithEmptyOutlets(path);
    }

    /** Navigate back */
    goBack() {
        this.navigationService.goBack();
    }

    /** @ignore */
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, jsdoc/require-jsdoc, @typescript-eslint/no-empty-function
    ngOnDestroy(): void {}

    /**
     * Generate sub navigation for each parrent route
     *
     * @param {Data} data - Route data
     * @param config TerminalConfig
     * @param event
     */
    private generateLayoutConfig(
        data: Data & { layoutConfig?: LayoutConfig },
        config: TerminalConfig,
        event: NavigationEnd,
    ): void {
        const currentNavigation = this.navigationService.currentNavigation || {
            finalUrl: { queryParams: { fromEncounter: null } },
        };
        const queryParams = currentNavigation.finalUrl?.queryParams ?? {};
        const assignedEncounterId = this.storageService.getItem('pdms.assignedEncounterId');
        const stateLayoutConfig = this.layoutService.getLayoutConfig();
        let { layoutConfig } = data;
        let items: DrawerNavItem[] = [],
            navbarItems: DrawerNavItem[] = [];
        if (!isEmpty(layoutConfig)) {
            if (layoutConfig.mergeWithParent) {
                layoutConfig = {
                    ...stateLayoutConfig,
                    ...layoutConfig,
                };
            }
            // Create new items array to trigger nav changes
            if (layoutConfig.items?.length) {
                items = layoutConfig.items.map(item => {
                    const newItem = { ...item };
                    // if custom logic
                    return newItem;
                });
            } else if (!layoutConfig.mergeWithParent) {
                items = [];
            }
            // Create new items array to trigger nav changes
            if (layoutConfig.hasNavbar) {
                navbarItems = this.navbarItems.map(item => {
                    const newItem = { ...item };
                    if (!isUndefined(item.key)) {
                        newItem.hidden = !config.license[item.key] || !config.features?.[item.key];
                    }
                    // Disable telemedicine icon when PIP call is running
                    if (item.key === 'telemedicine' && this.callWebrtcService.getIsPip$().getValue()) {
                        newItem.disabled = true;
                    }
                    // Show badge if Update is ready
                    if (item.link === '/settings') {
                        if (this.updateReady) {
                            item.badgeIcon = 'warning';
                        } else {
                            delete item.badgeIcon;
                        }
                    }

                    return newItem;
                });
                // Handle Encounter Assigned mode
                if (config.bedId && !isEmpty(assignedEncounterId)) {
                    navbarItems.unshift({
                        link: `/pdms/encounter/${assignedEncounterId}/overview`,
                        title: 'PDMS',
                        queryParams: { forceReload: true },
                        icon: 'assignment_ind',
                        key: 'pdms',
                    });
                }
            } else if (!layoutConfig.mergeWithParent) {
                navbarItems = [];
            }

            this.layoutService.setLayoutConfig({ ...layoutConfig, navbarItems, items });
        }
    }
}
