import { createReducer, on } from '@ngrx/store';
import * as AuditLogActions from '../actions/audit.log.actions';
import { AuditEvent } from '../../../../../shared/src/lib/types/r4b.type';

/**
 * Audit Log State
 */
export interface AuditLogState {
    auditLogs: AuditEvent[];
    loading: boolean;
    error: any | null;
}

/**
 * initial Audit Log State
 */
export const initialState: AuditLogState = {
    auditLogs: [],
    loading: false,
    error: null,
};

/**
 * Audit Log reducer
 */
export const auditLogReducer = createReducer(
    initialState,
    on(AuditLogActions.loadAuditLogs, state => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(AuditLogActions.loadAuditLogsSuccess, (state, { auditLogs }) => ({
        ...state,
        auditLogs,
        loading: false,
    })),
    on(AuditLogActions.loadAuditLogsFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: error.message || 'An unknown error occurred',
    })),
);
