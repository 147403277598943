import { Location } from '@angular/common';
import { forwardRef, inject, Inject, Injectable, InjectionToken } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    Data,
    DefaultUrlSerializer,
    Navigation,
    NavigationBehaviorOptions,
    NavigationEnd,
    NavigationExtras,
    Params,
    Router,
    RouterState,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { OUTLETS_INITIAL } from '@environment';
import { WINDOW } from '@ng-web-apis/common';
import { from, Observable, Subject } from 'rxjs';
import { filter, map, pairwise, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { WithLogger } from '@mona/shared/logger';
import { clone, escapeRegExp, outletRouteMatcher, pick } from '../helpers';

/**
 * Outlet Name Type
 */
export type OutletName = keyof typeof OUTLETS_INITIAL;

/**
 *  Outlet State
 */
export type OutletState = {
    /**
     * Should disable backdrop click to close sidenav
     */
    disableClose?: boolean;
    /**
     * Should open or close sidenav
     */
    opened: boolean;
    /**
     * Material Drawer Mode
     */
    mode?: MatDrawerMode;
    /**
     * Any Data
     */
    data?: Data;
};

/** Outlet Navigation Options */
export interface OutletOptions {
    /** Attr `queryParams` */
    queryParams?: Params;
    /** Attr `data` */
    data?: Data;
    /** Attr `mode` */
    mode?: MatDrawerMode;
    /** Attr `skipLocationChange` */
    skipLocationChange?: boolean;
}

/** get ActivatedRouteSnapshot */
export function getActivatedRouteFactory() {
    const ar = inject(ActivatedRoute);
    return () => ar;
}

/**
 * Define a service that exposes the back navigation
 */
export const NAVIGATION = new InjectionToken<NavigationService>('Navigation Service', {
    providedIn: 'root',
    factory: () => new NavigationService(inject(WINDOW), inject(Router), inject(Location)),
});

const OUTLET_SKIP_LOCATION_CHANGE = false;

/**
 * Service for wrapping the back navigation
 *
 * listening to router events of type NavigationEnd to manage an app-specific navigation history.
 * if the history still contains entries after popping the current URL off of the stack, we can safely navigate back.
 * otherwise we're falling back to the application route:
 *
 * @tutorial https://dev.to/angular/how-to-navigate-to-previous-page-in-angular-16jm
 */
@WithLogger({
    methodOptions: {
        withClassProperties: ['currentUrl', 'prevUrl', 'currentNavigation', 'currentLocationState'],
    },
    loggedMethodsNames: ['navigateByUrl', 'navigateToAuxilaryOutlet', 'closeAllOutlets', 'closeOutlet'],
})
export class NavigationService extends DefaultUrlSerializer {
    /** Collection of opened outlets */
    private openedOutlets: { [key: OutletName]: Navigation } = {};
    /** Custom store of navigated urls without outlets */
    private history: string[] = [];
    /** Identifies if previous path was lost connection modal window */
    private isPreviousLostConnection = false;
    /** get ActivatedRouteSnapshot */
    getActivatedRoute = getActivatedRouteFactory();
    /** Current router url */
    get currentUrl(): string {
        return this.router?.url;
    }
    /** Previous router url */
    get prevUrl(): string {
        return this.history?.at(-1);
    }
    /**
     * Information about a navigation operation.
     * Retrieve the most recent router navigation object
     */
    get currentNavigation(): Navigation {
        return this.router?.getCurrentNavigation();
    }
    /**
     * returns normalized URL path.
     */
    get currentLocationPath(): string {
        return this.location?.path();
    }
    /**
     * Reports the current state of the location history.
     *
     * @returns The current value of the `history.state` object.
     */
    get currentLocationState(): AnyObject | undefined {
        return this.location?.getState() as AnyObject | undefined;
    }
    /** Router state */
    get routerState(): RouterState {
        return this.router?.routerState;
    }

    /**
     * Constructor
     *
     * @param window
     * @param router
     * @param location
     */
    constructor(@Inject(WINDOW) public window: Window, private router: Router, private location: Location) {
        super();
        this.router.events.pipe(filter<NavigationEnd>(event => event instanceof NavigationEnd)).subscribe(event => {
            // store only clear url without outlet(s) part
            const url = event.urlAfterRedirects.split('(')[0];
            this.prevUrl !== url && this.history.push(url);
        });
        this.subscribeOnPreviousRouteWithOutlets();
    }

    /**
     * subscribe on previous route with outlets
     */
    subscribeOnPreviousRouteWithOutlets() {
        this.router.events
            .pipe(
                filter<NavigationEnd>(event => event instanceof NavigationEnd),
                pairwise(),
            )
            .subscribe(([prevUrl]) => {
                this.isPreviousLostConnection = prevUrl?.url?.includes('diagnostics/server-unavailable');
            });
    }

    /**
     * ⬅️ Navigates back in history
     *
     * If `soft` - try to `location.back()`
     * Uses custom logic instead
     * to properly navigate to previous url without outlet(s) and
     * to properly navigate to parent route if page was refreshed
     *
     * @param soft
     * @param fallBackRoute
     */
    goBack(soft = false, fallBackRoute = ''): void {
        if (soft && !this.isPreviousLostConnection) {
            return this.location.back();
        }

        if (!this.isPreviousLostConnection) {
            this.history.pop();
        }

        const urlTree = this.parse(this.currentLocationPath, true);

        if (this.history.length > 1) {
            this.router.navigateByUrl(this.prevUrl);
        } else if (fallBackRoute) {
            this.router.navigateByUrl(fallBackRoute);
        } else if (urlTree.root.hasChildren) {
            urlTree.root.children.primary.segments.pop();
            this.router.navigateByUrl(urlTree);
        } else {
            this.router.navigateByUrl('/');
        }
    }

    /**
     * Reload window
     *
     * @param hard
     */
    reload(hard = false) {
        if (hard) {
            this.window.location.reload(); // this.window.location.replace('/');
        }
        const currentUrl = this.history.pop() || '/';
        this.location.go(currentUrl);
    }

    /**
     * Navigates to a view using an absolute route path.
     *
     * @param url
     * @param extras
     */
    navigateByUrl(url: string | UrlTree, extras?: NavigationBehaviorOptions): Promise<boolean> {
        return this.router.navigateByUrl(url, extras);
    }

    /**
     * Navigates to a view using an absolute route path.
     *
     * Wraps {@link Router.navigate}
     * @param commands
     * @param extras
     */
    navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate(commands, extras);
    }

    /**
     * ➡️ Open (navigate) to auxilary outlet
     *
     * @param path
     * @param outlet
     * @param options
     */
    navigateToAuxilaryOutlet(
        path: string[],
        outlet: OutletName = 'side',
        {
            queryParams = undefined,
            data = undefined,
            mode = 'over',
            skipLocationChange = OUTLET_SKIP_LOCATION_CHANGE,
        }: OutletOptions = {},
    ): {
        afterOpened: () => Subject<OutletState['data']>;
        afterClosed: () => Subject<OutletState['data']>;
    } {
        // To ensure that there is no "null" or empty string in the path (that might break navigation)
        const normalizedPath = path.filter(Boolean);
        // to store url after navigation
        let navigatedUrl = '';
        // to mimic matdialog behavior after open
        const afterOpened$ = new Subject();
        const afterClosed$ = new Subject();
        const navigationRef = {
            afterOpened: () => afterOpened$,
            afterClosed: () => afterClosed$,
        };
        const state: OutletState = {
            disableClose: true,
            opened: true,
            mode,
            data,
        };
        const navigationExtras: NavigationExtras = {
            state,
            queryParamsHandling: 'merge',
            skipLocationChange,
        };
        const urlTree: UrlTree = super.parse(this.currentUrl);
        const outletTree = this.router.createUrlTree(normalizedPath);

        // extend/replace currrent url tree with outlet tree
        urlTree.root.children[outlet] = outletTree.root.children['primary'];
        // transform to observable to get url after navigation and emit `afterOpened`
        from(
            this.router.navigateByUrl(urlTree, {
                queryParams,
                ...navigationExtras,
            }),
        )
            .pipe(
                tap(() => {
                    navigatedUrl = this.currentUrl;
                    afterOpened$.next((this.currentLocationState as OutletState)?.data);
                    afterOpened$.complete();
                }),
                switchMap(() => {
                    // emit data (if provided via locationState) when exact outlet will be closed and emit `afterClosed`
                    return this.router.events.pipe(
                        filter(event => {
                            if (event instanceof NavigationEnd) {
                                const { urlAfterRedirects } = event;
                                const urlSplit = navigatedUrl.split(new RegExp(escapeRegExp(`(${outlet}:`)))[0];
                                return urlAfterRedirects === urlSplit;
                            }
                            return false;
                        }),
                        take(1),
                        map(() => (this.currentLocationState as OutletState)?.data),
                        takeUntil(afterClosed$),
                    );
                }),
                tap(() => {
                    afterClosed$.next((this.currentLocationState as OutletState)?.data);
                    afterClosed$.complete();
                }),
            )
            .subscribe();

        return navigationRef;
    }

    /**
     * If currently outlet is opened (by current location path)
     *
     * @param outlet
     * @param path
     */
    hasOpenOutlet(outlet: OutletName, path?: string | RegExp): boolean {
        const match = outletRouteMatcher(this.currentLocationPath, outlet as any);
        if (path) {
            return match && this.currentUrlMatches(path);
        }
        return match;
    }

    /**
     * Close (reset) all auxiliary outlets
     *
     * @param persistQueryParams
     * @param reopenPreviousOutlet
     */
    closeAllOutlets(persistQueryParams = true, reopenPreviousOutlet = false): Promise<any> {
        /* if (!outletRouteMatcher(this.currentUrl, 'side')) {
            return Promise.resolve();
        } */
        const command = { outlets: clone(OUTLETS_INITIAL) };
        const state: OutletState = {
            ...this.currentLocationState,
            opened: false,
        };
        delete state.data;
        const navigationExtras: NavigationExtras = {
            state,
            queryParamsHandling: persistQueryParams ? 'merge' : undefined,
            skipLocationChange: OUTLET_SKIP_LOCATION_CHANGE,
        };
        // INFO: here can safely use `location.back` - it will either open previous outlet or close existing if there was no
        if (reopenPreviousOutlet) {
            this.goBack(true);
            return Promise.resolve();
        }
        const activatedRoute = this.getActivatedRoute();
        return this.router.navigate(['', command], {
            relativeTo: activatedRoute.root,
            ...navigationExtras,
        });
    }

    /**
     * Close 1 outlet by name, with data passed to location state
     *
     * @param outlet
     * @param data
     */
    closeOutlet(outlet: OutletName, data: any): Promise<any> {
        const urlTree: UrlTree = this.parse(this.currentUrl);
        if (!urlTree.root.hasChildren || !urlTree.root.children[outlet]) {
            return;
        }
        delete urlTree.root.children[outlet];
        const state: OutletState = {
            ...this.currentLocationState,
            opened: false,
            data,
        };
        const navigationExtras: NavigationExtras = {
            state,
        };
        return this.router.navigateByUrl(urlTree, navigationExtras);
    }

    /**
     * ⬅️ Navigate to url and empty any outlet (close outlet)
     *
     * @param url
     * @param reopenOutletPath
     */
    navigateByUrlWithEmptyOutlets(url: string, reopenOutletPath = []): void {
        const urlTree: UrlTree = this.parse(url, true);
        const state: OutletState = {
            ...this.currentLocationState,
            opened: false,
        };
        const navigationExtras: NavigationExtras = {
            state,
        };

        this.router.navigateByUrl(urlTree, navigationExtras);
        // Reopen outlet in case of closure
        if (reopenOutletPath?.length) {
            this.navigateToAuxilaryOutlet(reopenOutletPath, 'side', { mode: 'side' });
        }
    }

    /**
     * If current url matches by string/regex
     *
     * @param path
     */
    currentUrlMatches(path: string | RegExp): boolean {
        return this.currentUrl?.search(path) > -1;
    }

    /**
     * Parse a url into a {@link UrlTree},
     * removes other children if `primaryOnly`
     *
     * @param url
     * @param primaryOnly
     */
    override parse(url: string, primaryOnly = false): UrlTree {
        const urlTree: UrlTree = super.parse(url);
        if (primaryOnly) {
            urlTree.root.children = pick(urlTree.root.children, 'primary');
        }
        return urlTree;
    }
}

/**
 * Guard to prevent navigation to any opened outlets
 *
 * If matches - returns clear URL tree with no outlets except ptimary
 */
@Injectable({ providedIn: 'root' })
export class NavigateClearOutletsGuard {
    /**
     * If matches - returns clear URL tree with no outlets except ptimary
     *
     * @param route
     * @param state
     */
    chekUrlForOpenedOutlets(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // eslint-disable-next-line curly
        if (!outletRouteMatcher(state.url, 'side')) return true;
        const redirectTree: UrlTree = this.navigationService.parse(state.url, true);
        return redirectTree;
    }

    /** @ignore */
    constructor(@Inject(forwardRef(() => NAVIGATION)) private navigationService: NavigationService) {}

    // eslint-disable-next-line @typescript-eslint/member-ordering
    canActivate = this.chekUrlForOpenedOutlets.bind(this);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    canActivateChild = this.chekUrlForOpenedOutlets.bind(this);
}
